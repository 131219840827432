<div id="main" [class]="user.theme" (click)="onDocumentClick($event)">
  <p-toast baseZIndex="10000" position="top-right"></p-toast>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptButtonStyleClass="btn btn-primary"
    rejectButtonStyleClass="btn btn-outline-primary"></p-confirmDialog>
  <!-- <g-sidebar></g-sidebar> -->
  <!-- <ngx-header></ngx-header> -->
  <!-- <router-outlet></router-outlet> -->
  <ngx-one-column-layout>
    <!-- <nb-menu *ngIf="menu && user.isAuthenticated" [items]="db.menus"></nb-menu> -->
    <router-outlet></router-outlet>
  </ngx-one-column-layout>
</div>
