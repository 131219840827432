import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as Msal from 'msal';

const localEnv = JSON.parse(localStorage.getItem('env') || '{}');
let env = { ...environment, ...localEnv, ...window['env'] };
const hostConfig = JSON.parse(localStorage.getItem('hostConfig') || '{}');
const msalObj = new Msal.UserAgentApplication(hostConfig.msalConfig || env.msalConfig);
msalObj.handleRedirectCallback(authCallBack);

function authCallBack(error, response) {
  if (error) {
    console.log(error);
  } else {
    if (response.tokenType === "id_token") {
      console.log("id_token acquired at: " + new Date().toString());
      console.log(msalObj.getAccount());
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static _profile: any = JSON.parse(localStorage.getItem('profile'));

  constructor(private http: HttpService) {
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  public static get loggedIn() {
    return !!(AuthService.profile || localStorage.getItem('authToken'));
  }

  public static get profile() {
    return AuthService._profile;
  }

  // updates profile locally.
  public static updateProfile(model: any) {
    const profile = AuthService.profile;

    profile.firstName = model.firstName;
    profile.lastName = model.lastName;
    profile.displayName = model.displayName;
    profile.name = model.displayName;
    profile.image = model.image;
    profile.theme = model.theme;
    profile.photoUrl = model.photoUrl;

    localStorage.setItem('profile', JSON.stringify(profile));
    Config.user = profile;
  }

  getMsalAccount() {
    return msalObj.getAccount();
  }

  msalLogin() {
    const hostConfig = JSON.parse(localStorage.getItem('hostConfig') || '{}');
    const msalObj = new Msal.UserAgentApplication(hostConfig.msalConfig || env.msalConfig);
    msalObj.loginRedirect(env.loginRequest);
  }

  login(model) {
    // var postData = 'grant_type=password&username=' + username + '&password=' + password;
    // const model = { username: username, password: password };

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const url = Config.getUrl('api/auth/login');
    return this.http.post(url, model).pipe(map((res: any) => {
      const data = res;
      if (data && data.profile) {
        data.profile.image = data.image;
        data.profile.theme = data.theme;
      }
      AuthService._profile = data.profile;
      localStorage.setItem('authToken', data.authToken);
      localStorage.setItem('roles', JSON.stringify(data.roles));
      localStorage.setItem('profile', JSON.stringify(data.profile));
      return data;
    }));

  }

  register(model: string) {
    return <Observable<any>>this.http.post(Config.urls.auth + 'register', model);
  }

  verifyEmail(code: string) {
    return <Observable<any>>this.http.get(`${Config.urls.auth}verifyEmail/${code}`);
  }

  validateForgotPasswordCode(code: string) {
    return this.http.get(`${Config.urls.auth}ValidateForgotPasswordCode/${code}`);
  }

  changePassword(model: any) {
    return <Observable<any>>this.http.post(Config.urls.auth + 'changePassword', model);
  }

  retrievePassword(email: string) {
    return <Observable<any>>this.http.post(Config.urls.auth + 'retrievePassword?email=' + email, null);
    // return <Observable<any>>this.http.post(Config.urls.auth + 'retrievePassword', JSON.stringify(email));
  }

  decrypt(data: string) {
    return this.http.get(`${Config.urls.auth}Decrypt?data=${data}`);
  }

  getIdToken() {
    return localStorage.getItem('msal.idtoken');
  }

  logoff() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('profile');
    if (this.getMsalAccount()) {
      msalObj.logout();
    };
  }
}
