import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Config } from './shared/services/config';
import { AuthService } from './shared/services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private confirmationService: ConfirmationService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (Config.user.isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Config.isDirty) {
      // return confirm('You may have unsaved changes. Are you sure you want to exit?');
      const subject = new Subject<boolean>();

      this.confirmationService.confirm(<any>{
        message: 'Unsaved changes will be lost. Are you sure you want to navigate away?',
        icon: 'fa fa-question text-warning',
        accept: () => {
          // this.accept.emit();
          subject.next(true);
          return true;
        },
        reject: () => {
          // reject action
          subject.next(false);
          return false;
        }
      });
      return subject.asObservable();
    }

    return true;
  }
}
