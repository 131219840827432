<div class="header-container">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" (click)="toggleSidebar()">
      <img class="sd-icon" alt="Quizale" src="./images/logo.png">
    </a>
    <a *ngIf="user?.isAuthenticated" href="#">
      <span class="title">
        <img class="logo px-2" alt="Quizale" [src]="logoUrl" />
      </span>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()">Quizale <span></span></a> -->
  </div>
</div>
<div class="header-container">
  <div *ngIf="!readOnlyMode" class="dropdown me-4 d-none d-md-block">
    <button type="button" class="btn btn-outline-info white dropdown-toggle" data-bs-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      <i class="fa fa-globe white mr-1 d-none d-md-inline-block"></i> {{db.lang}}
    </button>
    <div class="dropdown-menu">
      <div class="dropdown-item" href="javascript:void();" *ngFor="let item of languages"
        (click)="onLanguageChanged(item.code);">
        <i class="fa fa-globe blue mr-1"></i> {{item.name}}
      </div>
    </div>
  </div>

  <div class="user-section dropdown" *ngIf="user?.isAuthenticated">
    <span class="row username" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <div class="col-auto p-1">
        <img class="user-avatar" [src]="user.image || fallbackImage" [alt]="user.displayName" />
      </div>
      <div class="col p-1 d-none d-md-inline-block">
        <div class="dropdown-toggle">{{ user.name | truncate:[12] }}</div>
        <div class="role">{{user.role}} <span *ngIf="user.role == 'student'">({{user.class?.name}})</span></div>
      </div>
    </span>
    <div *ngIf="!readOnlyMode" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="javascript:void();" [routerLink]="['/users/my-profile']">
        <i class="fa fa-user-alt pr-2 fuchsia"></i> {{db.labels.myProfile}}
      </a>
      <a class="dropdown-item" href="javascript:void();" [routerLink]="['/users/change-password']">
        <i class="fa fa-key pr-2 olive"></i> {{db.labels.changePassword}}
      </a>
      <a class="dropdown-item" href="javascript:void();" (click)="expireCache();">
        <i class="fa fa-chalkboard-teacher pr-2 green"></i> {{db.labels.refreshCache}}
      </a>
      <a class="dropdown-item" href="javascript:void();" (click)="onAvatarChange();">
        <i class="fa fa-solid fa-user-astronaut pr-2 violet"></i>
        {{db.labels.setAvatar || 'Change Avatar'}}
      </a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void();" (click)="logoff();">
        <i class="fa fa-sign-out-alt pr-2 red"></i> LogOff
      </a>
    </div>
    <div *ngIf="readOnlyMode" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="javascript:void();" (click)="logoff();">
        <i class="fa fa-sign-out-alt pr-2 red"></i> LogOff
      </a>
    </div>
  </div>
  <p-dialog *ngIf="avatarDialog.visible" header="Choose Your Avatar" [(visible)]="avatarDialog.visible" [modal]="true"
    styleClass="dialog-avatar">
    <div class="row">
      <div class="col text-center" *ngFor="let item of avatars">
        <img class="avatar mb-3" [src]="item.img" [alt]="user.displayName" (click)="setAvatar(item);" />
      </div>
    </div>
    <!-- <div class="text-center">
      <button class="btn btn-primary w-50" gText="ok"></button>
    </div> -->
  </p-dialog>
</div>