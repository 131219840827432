import { Injectable } from '@angular/core';
import { G } from '../../g';

@Injectable()
export class User {

    id: number;
    guid: string;
    name: string;
    email: string;
    code: string;
    userType: string;
    image?: string;
    displayName?: string;
    roles: string[];
    role: string;
    theme?: string;
    class: any = {};
    photoUrl?: string;

    constructor() {
        const profile = G.storage.getJSON('profile');
        if (profile) {
            this.name = profile.name;
            this.id = profile.id;
            this.guid = profile.guid;
            this.email = profile.email;
            this.userType = profile.userType || profile.role;
            this.role = this.userType;
            this.theme = profile.theme || 'blue';
            this.image = profile.image;
            this.class = profile.class;
            this.photoUrl = profile.photoUrl;
        }
        this.roles = G.storage.getJSON('roles') || [];
        this.roles = this.roles.map(x => x.toUpperCase());
        if (!this.role && this.roles.length >= 1) {
            this.role = this.roles[0];
        }
    }

    saveToLocalStorage() {
        let profile = G.storage.getJSON('profile');
        profile.image = this.image;
        profile.theme = this.theme;

        localStorage.setItem('profile', JSON.stringify(profile));
    }

    public get isAdmin(): boolean {
        return this.userType.toLowerCase() === 'admin' || this.isInRole('Admin');
    }

    public get isAuthenticated(): boolean {
        return !!this.id;
    }

    public isInRole(roleName: string) {
        const name = roleName.toUpperCase();
        return this.roles.includes(name);
    }
}
