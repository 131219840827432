import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Config } from '../../shared/services/config';
import { HttpService } from '../../shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  url = Config.urls.masterData;

  constructor(private http: HttpService) {
    // this.loadDropdowns();
    // this.loadGridColumns();
    // this.loadMenus();
    this.loadHostConfigs();
    this.loadQuizConfigs();
    this.loadTenantByHostname();
  }

  expireCache() {
    return this.http.get(Config.urls.cache + 'expire');
  }

  getGenders() {
    return [
      { id: 'M', name: 'Male' },
      { id: 'F', name: 'Female' },
      { id: 'A', name: 'All' },
    ];
  }

  loadDropdowns() {
    this.http.getAll(Config.getUrl('api/Dropdowns/')).subscribe((res: any) => {
      Config.ddl = res;
    });
  }

  loadGridColumns() {
    this.http.getAll(Config.getUrl('api/Grid/Json')).subscribe((res: any) => {
      Config.columns = res;
      localStorage.setItem('columns', JSON.stringify(Config.columns));
    });
  }

  loadTenantByHostname() {
    this.getTenantByHostname().subscribe(res => {
      if (res && res.id) {
        Config.tenant = res;
        console.log('Tenant');
        console.log(Config.tenant);
        localStorage.setItem('tenant', JSON.stringify(Config.tenant));
        localStorage.setItem('tenantId', Config.tenant.id);
      } else {
        if (!window.location.href.includes('/public/school-not-found')) {
          window.location.href = '/public/school-not-found';
        }
      }
    });
    // return this.http.get(`${Config.urls.masterData}getTenantByHostname?hostname=${hostname}`);
  }

  loadHostConfigs() {
    this.getHostConfig().subscribe(res => {
      if (res) {
        res.msalConfig = res.msalConfig ? JSON.parse(res.msalConfig) : null;
        Config.hostConfig = res;
      }
    });
  }

  loadQuizConfigs() {
    this.getQuizConfigs().subscribe(res => {
      Config.quizConfigs = res || [];
    });
  }

  getTranslation(lang: string) {
    return this.http.get(Config.urls.translation + lang);
  }

  async loadTranslationData(lang?: string) {
    lang = lang || Config.lang;
    let res = await fetch(`/assets/i18n/${lang}.json`);
    let i18n = await res.json();
    this.getTranslation(lang).subscribe(res => {
      Config.labels = { ...i18n, ...res };
      localStorage.setItem('labels', JSON.stringify(Config.labels));
    });
  }

  loadMenus(lang: string = Config.lang) {
    this.getMenus().subscribe(res => {
      Config.menus = res;
    });
  }

  getClasses = () => this.http.get(Config.urls.classes);

  getMenus(lang: string = Config.lang) {
    if (Config.user && Config.user.id) {
      lang = localStorage.getItem('lang') || Config.lang;
      const menuType = Config.user.roles[0] || '';
      return this.http.get(Config.urls.menus + lang + '/?menuType=' + menuType);
    } else {
      return of([]);
    }
  }

  getMenuGroups() {
    return <Observable<any>>this.http.get(Config.urls.base + 'menu/getMenuGroups');
  }
  getCountries() {
    return <Observable<any>>this.http.get(Config.urls.masterData + 'GetCountries');
  }

  getTenantByHostname(hostname: string = '') {
    hostname = hostname || Config.hostname;
    console.log(hostname);
    return this.http.get(`${Config.urls.masterData}getTenantByHostname?hostname=${hostname}`);
  }

  getHostConfig(hostname: string = '') {
    hostname = hostname || Config.hostname;
    console.log(hostname);
    return this.http.get(`${Config.urls.masterData}GetHostConfigs?hostname=${hostname}`);
  }


  getQuizConfigs(hostname: string = '') {
    hostname = hostname || Config.hostname;
    return this.http.get(`${Config.urls.masterData}GetQuizConfigs?hostname=${hostname}`);
  }

  getAuthors() {
    return <Observable<any>>this.http.get(Config.urls.masterData + 'authors');
  }

  getLanguages(): Observable<any[]> {
    return <Observable<any>>this.http.get(Config.urls.masterData + 'languages');
  }

  getQuizTypes = () => this.http.get(Config.urls.masterData + 'quizTypes');
  getSubjects = () => this.http.get(Config.urls.masterData + 'subjects');

  getFaqs(): Observable<any[]> {
    return <Observable<any>>this.http.get(Config.urls.masterData + 'faqs');
  }

  createTenant(model: any) {
    return this.http.post(Config.urls.base + 'config/CreateNewTenant', model);
  }

}
