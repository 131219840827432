import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.routes').then(m => m.AdminRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.routes').then(m => m.AuthRoutes),
    },
    {
        path: 'badges',
        loadChildren: () => import('./badges/badges.routes').then(m => m.BadgesRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'deans',
        loadChildren: () => import('./deans/deans.routes').then(m => m.DeansRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'proctor',
        loadChildren: () => import('./proctor/proctor.routes').then(m => m.ProctorRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'public',
        loadChildren: () => import('./public/public.routes').then(m => m.PublicRoutes),
    },
    {
        path: 'network',
        loadChildren: () => import('./network/network.routes').then(m => m.NetworkRoutes),
    },
    {
        path: 'notice-board',
        loadChildren: () => import('./notice-board/notice-board.routes').then(m => m.NoticeBoardRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'question-bank',
        loadChildren: () => import('./question-bank/question-bank.routes').then(m => m.QuestionBankRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'quiz',
        loadChildren: () => import('./quiz/quiz.routes').then(m => m.QuizRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'gradings',
        loadChildren: () => import('./gradings/gradings.routes').then(m => m.GradingsRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'students',
        loadChildren: () => import('./students/students.routes').then(m => m.StudentsRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'setup',
        loadChildren: () => import('./setup/setup.routes').then(m => m.SetupRoutes),
        canActivate: [AuthGuard],
    },
    {
        path: 'subjects',
        loadChildren: () => import('./subjects/subjects.routes').then(m => m.SubjectRoutes),
    },
    {
        path: 'teachers',
        loadChildren: () => import('./teachers/teachers.routes').then(m => m.TeachersRoutes),
        canActivate: [AuthGuard],
    },
    { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: '**', redirectTo: 'pages' },
];
