import { enableProdMode, importProvidersFrom } from '@angular/core';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule } from '@nebular/theme';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { CoreModule } from './app/@core/core.module';
import { ThemeModule } from './app/@theme/theme.module';
import { AppComponent } from './app/app.component';
import { routes as AppRoutes } from './app/app.routes';
import { TextDirective } from './app/shared/directives/text.directive';
import { HttpExchangeInterceptor } from './app/shared/services/http-exchange.interceptor';
import { environment } from './environments/environment';

(window as any).global = window;

if (environment.production) {
  enableProdMode();
}

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');
const authToken = urlParams.get('authToken');
const roles = urlParams.get('roles');
const profile = urlParams.get('profile');
const hostname = urlParams.get('hostname');

if (lang) {
  localStorage.setItem('lang', lang);
}
if (authToken) {
  localStorage.setItem('authToken', authToken);
}
if (roles) {
  localStorage.setItem('roles', roles);
}
if (profile) {
  localStorage.setItem('profile', profile);
}
if (hostname) {
  localStorage.setItem('hostname', hostname);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(AppRoutes, withComponentInputBinding()), provideClientHydration(),
    importProvidersFrom(BrowserModule, ConfirmPopupModule, ConfirmDialogModule, NbSidebarModule.forRoot(),
      NbMenuModule.forRoot(), NbWindowModule.forRoot(),
      NbToastrModule.forRoot(), CoreModule.forRoot(), ThemeModule.forRoot(), BrowserModule, FormsModule, ToastModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpExchangeInterceptor,
      multi: true,
    },
    TextDirective,
    ConfirmationService,
    MessageService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
  .catch(err => console.error(err));
