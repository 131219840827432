import { Component } from '@angular/core';
import { Config } from '../../../shared/services/config';
import { FooterComponent } from '../../components/footer/footer.component';
import { NgIf } from '@angular/common';
import { HeaderComponent } from '../../components/header/header.component';
import { NbLayoutModule, NbSidebarModule } from '@nebular/theme';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
  standalone: true,
  imports: [
    NbLayoutModule,
    HeaderComponent,
    NgIf,
    NbSidebarModule,
    FooterComponent,
  ],
})
export class OneColumnLayoutComponent {
  db = Config;
  // showSidebar = Config.user && Config.user.isAuthenticated && !window.location.href.includes('/proctor');

  constructor() {
    Config.userChanged.subscribe(user => this.onUserChanged(user));
    this.setSidebarDisplay();
  }

  onUserChanged(user: any): void {
    this.setSidebarDisplay();
  }

  setSidebarDisplay() {
    Config.sidebar.display = (Config.user && Config.user.isAuthenticated); // && !window.location.href.includes('/proctor');

    if (localStorage.loginMode === 'quiz') {
      Config.sidebar.display = false;
    }
  }
}
