import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { Config } from '../../shared/services/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private http: HttpService) { }

  getUser(guid: string) {
    return <Observable<any>>this.http.get(Config.urls.appUsers + 'getUser/' + guid);
  }

  getByGuid(guid: string) {
    return <Observable<any>>this.http.get(Config.urls.users + 'getByGuid/' + guid);
  }

  getUsers(projectId: number) {
    return <Observable<any>>this.http.get(Config.urls.appUsers + 'getUsers/' + projectId);
  }

  getByClassGuid(guid: string) {
    return this.http.get(Config.urls.users + 'getByClassGuid/' + guid);
  }

  getReceivers(certificateTypeId: number) {
    return <Observable<any>>this.http.get(Config.urls.appUsers + 'getReceivers/' + certificateTypeId);
  }

  save(model: any) {
    return this.http.post(Config.urls.appUsers + 'save', model);
  }

  changePassword(model: any) {
    return this.http.post(Config.urls.users + 'changePassword', model);
  }

  changeUserPassword(guid: string, model: any) {
    return this.http.post(`${Config.urls.users}changeUserPassword/${guid}`, model);
  }

  getMappedSubjects(guid: string, mappedOnly: number = 0) {
    return this.http.getAll(`${Config.urls.base}DB/Query/GetMappedSubjects?UserGuid=${guid}&MappedOnly=${mappedOnly}`);
  }

  getMappedSubjectsByClassGuid(guid: string, mappedOnly: number = 0) {
    return this.http.getAll(`${Config.urls.base}DB/Query/GetMappedSubjects?ClassGuid=${guid}&MappedOnly=${mappedOnly}`);
  }

  getMyProfile() {
    return <Observable<any>>this.http.get(Config.urls.users + 'myProfile/');
  }

  getProfile(id: number) {
    return <Observable<any>>this.http.get(Config.urls.users + 'getProfile/' + id);
  }

  saveMappedSubjects(map: any[]): any {
    return this.http.post(`${Config.urls.users}SaveMappedSubjects`, map);
  }

  saveMappedSubjectsForClass(map: any[]): any {
    return this.http.post(`${Config.urls.users}SaveMappedSubjectsForClass`, map);
  }

  sendFeedback(model: any) {
    return <Observable<any>>this.http.post(Config.urls.users + 'feedback', model);
  }

  updateProfile(model: any) {
    return <Observable<any>>this.http.post(Config.urls.users + 'updateProfile', model);
  }

  updateSettings(model: any) {
    return <Observable<any>>this.http.post(Config.urls.users + 'updateSettings', model);
  }

  uploadPhoto(model: FormData) {
    return this.http.post(Config.getUrl('api/upload/userPhoto'), model);
  }
}
