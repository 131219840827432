import { Component } from '@angular/core';
import { User } from '../../../shared/models/user';
import { Config } from '../../../shared/services/config';
import { UserService } from '../../../shared/services/user.service';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [NgIf, NgFor],
})
export class FooterComponent {
  colors = [
    { name: 'blue' },
    { name: 'fuchsia' },
    { name: 'olive' },
    { name: 'purple' },
    { name: 'orange' },
    { name: 'maroon' },
  ];
  user = Config.user;
  currentYear: number = new Date().getFullYear();

  constructor(private userService: UserService) {
    Config.userChanged.subscribe(user => this.onUserChanged(user));
  }

  onUserChanged(user: User): void {
    this.user = user;
    if (this.user && this.user.isAuthenticated) {
    }
  }

  onColorChange(color: any) {
    let el = document.getElementById('main');
    // if (el) {
    //   el.className = color.name || 'blue';
    // }
    Config.user.theme = color.name;
    const model = {
      image: Config.user.image,
      theme: Config.user.theme,
    };
    Config.user.saveToLocalStorage();
    this.userService.updateSettings(model).subscribe(res => {
      console.log(res);
    });
    Config.themeChanged.emit(color.name);
  }
}
