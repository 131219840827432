import { NgIf } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NbIconLibraries, NbMenuModule, NbMenuService, NbSidebarService } from '@nebular/theme';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { LayoutService } from './@core/utils';
import { OneColumnLayoutComponent } from './@theme/layouts/one-column/one-column.layout';
import { MENU_ITEMS } from './quizale-menu';
import { User } from './shared/models/user';
import { Config } from './shared/services/config';
import { MasterService } from './shared/services/master.service';

@Component({
  selector: 'ngx-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    ToastModule,
    ConfirmDialogModule,
    OneColumnLayoutComponent,
    NgIf,
    NbMenuModule,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  @Input() lang = 'en';
  @Input() authToken = '';
  @Input() roles = '';
  @Input() profile = '';

  menuService = inject(NbMenuService);

  menu = MENU_ITEMS || Config.menus;
  db = Config;
  direction = 'ltr';
  user = Config.user;
  // menu = MENU_ITEMS;
  evaIcons = [];

  constructor(iconsLibrary: NbIconLibraries, private masterService: MasterService, private router: Router,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,) {
    iconsLibrary.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('ion', { iconClassPrefix: 'ion' });

    this.setLocalStorageFromQueryString();

    iconsLibrary.setDefaultPack('fa');
    this.setRoute();

    Config.labels = JSON.parse(localStorage.getItem('labels')) || Config.labels;
    // Config.columns = JSON.parse(localStorage.getItem('columns')) || Config.columns;
    this.masterService.loadTranslationData();
    // this.loadMenu();
    Config.userChanged.subscribe((user: User) => this.onUserChanged(user));
  }

  setLocalStorageFromQueryString() {
    if (this.lang) {
      localStorage.setItem('lang', this.lang);
      Config.lang = this.lang;
    }
    if (this.authToken) {
      localStorage.setItem('authToken', this.authToken);
    }
    if (this.roles) {
      localStorage.setItem('roles', this.roles);
    }
    if (this.profile) {
      localStorage.setItem('profile', this.profile);
    }
    Config.user = new User();
  }

  setRoute() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        Config.currentRoute.state = this.router.getCurrentNavigation().extras.state || {};
      }
    });
  }

  private loadMenu() {
    // this.masterService.getMenus().subscribe(res => {
    //   this.db.menus = res;
    //   this.menu = res;
    //   this.menu.find(x => x.id === 66).link = '/home';
    //   this.menu.find(x => x.id === 66).children = null;
    //   this.parseMenu();
    //   console.log(this.menu);
    // });
  }

  // private parseMenu() {
  //   this.menu.forEach(x => {
  //     x.key = this.camelCase(x.key);
  //     x.title = this.db.labels[x.key] || x.title;

  //     if (x.children) {
  //       x.children.forEach(c => {
  //         c.key = this.camelCase(c.key);
  //         c.title = this.db.labels[c.key] || c.title;
  //       });
  //     }
  //   });
  // }

  camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  ngOnInit(): void {
    // this.analytics.trackPageViews();
    if (Config.lang === 'he' || Config.lang === 'ar') {
      this.direction = 'rtl';
    }
    this.db.menus = MENU_ITEMS;

    this.menuService.onItemClick().subscribe((event: any) => {
      if (event.item?.id === 66) {
        let url = Config.env.urls.networkUrl + '?token=' + localStorage.authToken;
        window.open(url, '_blank').focus();
      }
      console.log(event);
    });
  }

  onDocumentClick(event: any) {
    // if (Config.isSmallScreen) {
    //   this.sidebarService.collapse('menu-sidebar');
    // }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  onUserChanged(user: any): void {
    this.user = user;
    if (this.user && this.user.isAuthenticated) {
      this.loadMenu();
    }
  }
}
