
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsgService } from './msg.service';

@Injectable()
export class HttpExchangeInterceptor implements HttpInterceptor {
    uploadUrls = ['/api/upload/', '/api/DataSetup/PostSetupData'];
    ignoreAuthUrls = [
        'https://api.quizale.com/auth/login',
    ];

    constructor(private router: Router, private msg: MsgService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        if (!this.ignoreAuthUrls.some(url => request.url.includes(url))) {
            const headerJson = this.setAuthHeaders();
            if (this.uploadUrls.some(url => request.url.includes(url))) {
                request.headers.delete('Content-Type');
                delete headerJson['Content-Type'];
            }
            request = request.clone({
                setHeaders: headerJson,
            });
        }
        return next.handle(request).pipe(catchError(err => {
            this.msg.error('Some error occurred while processing your request.');
            console.log(err);
            return of('Some error occurred while processing your request.');
        }));

        // return next.handle(request).pipe(tap(event: HttpEvent<any>) => {
        //     if (event instanceof HttpResponse) {
        //         // do stuff with response if you want
        //     }
        // }, (res: any) => {
        //     if (res instanceof HttpErrorResponse) {
        //         if (res.status === 0 && res.statusText === 'Unknown Error') {
        //             this.msg.error('Could not reach the service.', null);
        //             // this.authService.login();
        //             return of(res);
        //         } else if (res.status === 401 || res.status === 403) {
        //             // this.authService.login();
        //             this.router.navigateByUrl('/login');
        //         } else {
        //             this.msg.error('Some error occurred while processing your request.');
        //             return of(res);
        //         }
        //         return observableThrowError(res);
        //     }
        // });
    }

    getLocation(href) {
        const match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/);
        return match && {
            protocol: match[1],
            host: match[2],
            hostName: match[3],
            port: match[4],
            pathName: match[5],
            search: match[6],
            hash: match[7],
        };
    }

    setAuthHeaders(): any {
        const headerJson = {};
        const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
        const tenantId = localStorage.getItem('tenantId');
        if (token) {
            headerJson['Content-Type'] = 'application/json';
            // headerJson['Authorization'] = 'Bearer ' + token;
            headerJson['AuthToken'] = token;
            headerJson['Hostname'] = localStorage.getItem('hostname');
            headerJson['TenantId'] = tenantId || '0';
        }
        return headerJson;
    }
}
