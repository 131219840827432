import { AppStorage } from './@js/app-storage';

export class G {
    static storage = new AppStorage();

    static string = {
        equalsCI: (s1: string, s2: string) => (s1 || '').toLowerCase() === (s2 || '').toLowerCase(),
        titleCase: s => {
            s = s.replace(/([A-Z])/g, ' $1').trim();
            s = s.charAt(0).toUpperCase() + s.slice(1);
            return s;
        },
        camelCase: s => {
            return s.charAt(0).toLowerCase() + s.slice(1);
        }
    };

    static newGuid() {
        return crypto.randomUUID();
        // const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        // const uuid = new Array(36);
        // let rnd = 0;
        // let r: number;
        // for (let i = 0; i < 36; i++) {
        //     if (i === 8 || i === 13 || i === 18 || i === 23) {
        //         uuid[i] = '-';
        //     } else if (i === 14) {
        //         uuid[i] = '4';
        //     } else {
        //         if (rnd <= 0x02) rnd = 0x2000000 + (Math.random() * 0x1000000) | 0;
        //         r = rnd & 0xf;
        //         rnd = rnd >> 4;
        //         uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
        //     }
        // }
        // return uuid.join('');
    }

    static camelCase(o) {
        const res = {};
        let newKey;
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                res[newKey] = o[origKey];
            }
        }
        return res;
    }

    static toCamel(o) {
        let newO, origKey, newKey, value;
        if (o instanceof Array) {
            return o.map((value) => {
                if (typeof value === "object") {
                    value = this.toCamel(value);
                }
                return value;
            })
        } else {
            newO = {}
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey]
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    static dhmShort(utc: string) {
        if (!utc) {
            return '';
        }
        const str = utc.replace('T', ' ') + ' GMT';
        const utcDate = new Date(str);
        const diff = Math.abs(Date.now() - utcDate.getTime());
        let minutes = Math.floor((diff / 1000) / 60);

        let res = '';

        const minutesInDay = 24 * 60;

        if (minutes > minutesInDay) {
            res += Math.floor(minutes / minutesInDay) + 'd';
            minutes = minutes % minutesInDay;
            return res;
        }
        if (minutes >= 60) {
            res += Math.floor(minutes / 60) + 'h';
            minutes = minutes % 60;
            return res;
        }
        if (minutes > 0) {
            res += minutes + 'm';
        }
        return res || '0m';
    }

    static dhmFromUTC(utc: string) {
        const str = utc.replace('T', ' ') + ' GMT';
        const utcDate = new Date(str);
        const diff = Math.abs(Date.now() - utcDate.getTime());
        const minutes = Math.floor((diff / 1000) / 60);
        return G.dhm(minutes);
    }

    static daysFromUTC(utc: string) {
        if (!utc) {
            return 0;
        }
        const str = utc.replace('T', ' ') + ' GMT';
        const utcDate = new Date(str);
        const diff = utcDate.getTime() - Date.now();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.floor(diff / oneDay);
    }

    static dhm(minutes: number) {
        let res = '';

        const minutesInDay = 24 * 60;

        if (minutes > minutesInDay) {
            res += Math.floor(minutes / minutesInDay) + 'd ';
            minutes = minutes % minutesInDay;
        }
        if (minutes >= 60) {
            res += Math.floor(minutes / 60) + 'h ';
            minutes = minutes % 60;
        }
        if (minutes > 0) {
            res += minutes + 'm';
        }
        return res || '0m';
    }

    static getFrame(videoEl, scale) {
        scale = scale || 1;

        const canvas = document.createElement("canvas");
        canvas.width = videoEl.clientWidth * scale;
        canvas.height = videoEl.clientHeight * scale;
        canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();

        // const image = new Image()
        // image.src = canvas.toDataURL();
        // return image;
    }

    // Parses date from 2021-05-21T21:19:00 format to Date object in current time zone.
    static parseDate(dateString: string) {
        if (dateString) {
            const str = dateString.replace('T', ' ') + ' GMT';
            return new Date(str);
        } else {
            return null;
        }
    }
}
