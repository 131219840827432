import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home',
    link: '/pages/iot-dashboard',
  },
  {
    title: 'Loading...',
    icon: 'layout-outline',
    // children: [
    //   {
    //     title: 'Stepper',
    //     link: '/pages/layout/stepper',
    //   },
    //   {
    //     title: 'List',
    //     link: '/pages/layout/list',
    //   },
    //   {
    //     title: 'Infinite List',
    //     link: '/pages/layout/infinite-list',
    //   },
    //   {
    //     title: 'Accordion',
    //     link: '/pages/layout/accordion',
    //   },
    //   {
    //     title: 'Tabs',
    //     pathMatch: 'prefix',
    //     link: '/pages/layout/tabs',
    //   },
    // ],
  },
  {
    title: 'Loading...',
    icon: 'edit-2-outline',
    children: [
      {
        title: 'Form Inputs',
        link: '/pages/forms/inputs',
      },
      {
        title: 'Form Layouts',
        link: '/pages/forms/layouts',
      },
      {
        title: 'Buttons',
        link: '/pages/forms/buttons',
      },
      {
        title: 'Datepicker',
        link: '/pages/forms/datepicker',
      },
    ],
  },
];
