<nb-layout windowMode2>
    <nb-layout-header fixed>
        <ngx-header></ngx-header>
    </nb-layout-header>

    <!-- <nb-sidebar *ngIf="db.sidebar.display" class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
    </nb-sidebar> -->

    <nb-layout-column id="mainLayout">
        <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

    <nav class="fixed">
        <ngx-footer></ngx-footer>
    </nav>
    <br>
    <br>
    <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
    </nb-layout-footer>
</nb-layout>