import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { MsgService } from './msg.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(public http: HttpClient, private msg: MsgService) { }

  setAuthHeaders() {
    const headerJson = {};
    const token = localStorage.getItem('adal.idtoken') || sessionStorage.getItem('adal.idtoken');
    if (token) {
      headerJson['Content-Type'] = 'application/json';
      headerJson['Authorization'] = 'Bearer ' + token;
    }
    return new HttpHeaders(headerJson);
  }

  get(url: string) {
    return this.http.get(url) as Observable<any>;
  }

  getAll(url: string) {
    return this.http.get(url) as Observable<any[]>;
  }

  post(url: string, model: any) {
    return this.http.post(url, model) as Observable<any>;
  }

  put(url: string, model: any) {
    return this.http.put(url, model);
  }

  delete(url: string) {
    return this.http.delete(url);
  }

  upload(url: string, model: any) {
    const formData: FormData = new FormData();
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        formData.append(key, model[key]);
      }
    }
    return this.http.post(url, formData);
  }

  handleException(err: any) {
    if (err.statusText === 'Unauthorized') {
      this.msg.error('Your session has been expired, please re-login.');
      // console.log();
      // location.reload();
    } else {
      this.msg.error(err.message || `Something went wrong while calling api.`);
      // console.log(err.message || `Something went wrong while calling api.`);
      return throwError(err);
    }
  }
}
