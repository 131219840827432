import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
} from '@nebular/theme';

import { FooterComponent, HeaderComponent } from './components';
import { OneColumnLayoutComponent } from './layouts';
// import { DEFAULT_THEME } from './styles/theme.default';
import { MATERIAL_LIGHT_THEME } from './styles/material/theme.material-light';
import { OverlayPanelModule } from 'primeng/overlaypanel';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
];

@NgModule({
    imports: [CommonModule, ...NB_MODULES, OverlayPanelModule, RouterModule, ...COMPONENTS],
    exports: [CommonModule, HeaderComponent, FooterComponent, ...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'material-light',
          },
          [MATERIAL_LIGHT_THEME],
        ).providers,
      ],
    };
  }
}
