import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Config } from '../services/config';

@Directive({
    selector: '[gText]',
    standalone: true
})
export class TextDirective implements OnChanges {
  @Input() gText: string;
  @Input() icon: string;
  db = Config;

  suffix = '';

  constructor(private el: ElementRef) {
    Config.langChanged.subscribe((lang: string) => this.onLangChanged(lang));
  }

  ngOnInit() {
    this.setText();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.input) {
      this.setText();
    }
  }

  onLangChanged(lang: string): void {
    this.setText();
  }

  setText() {
    if (this.gText) {
      if (this.gText.endsWith(':')) {
        this.suffix = ':';
        this.gText = this.gText.slice(0, -1);
      }
      const text = this.icon ? `<i class="${this.icon}"></i> ` : '';
      this.el.nativeElement.innerHTML = text + (this.db.labels[this.gText] || this.insertSpaces(this.gText)) + this.suffix;
    }
  }

  insertSpaces(str: string) {
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  }
}
