export class AppStorage {
    db = localStorage;

    constructor() {
    }

    get(key: string) {
        const item = this.db.getItem(key);
        if (item && item !== 'undefined') {
            return item;
        }
        return null;
    }

    getJSON(key: string) {
        return JSON.parse(this.get(key));
    }

    set(key: string, value: any) {
        let item = value;
        if (typeof value !== 'string') {
            item = JSON.stringify(value);
        }
        this.db.setItem(key, item);
    }
}
