import { EventEmitter, Injectable } from '@angular/core';
import { hostname } from 'os';
import _columns from '../../../data/columns.json';
import _ddl from '../../../data/ddl.json';
import _labels from '../../../data/labels.json';
import _i18n from '../../../assets/i18n/en.json';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { IHostConfig } from '../../@core/interfaces/host-config.interface';
import { IQuizConfig } from '../../@core/interfaces/quiz-config.interface';

const localEnv = JSON.parse(localStorage.getItem('env') || '{}');
let env = { ...environment, ...localEnv, ...window['env'] };
console.log(env);

@Injectable()
export class Config {
    static env = { ...environment, ...localEnv, ...window['env'] };
    static apiUrl = env.urls.apiUrl;
    static formats = {
        dateTime: 'MMM DD, YYYY hh:mm a',
        hourDateTime: 'MMM DD, YYYY hh:00 a',
        shortDateTime: 'DD/MM/YYYY HH:mm',
        sortDate: 'YYYY-MM-DD',
        date: 'MMM DD, YYYY',
        time: 'h:mm a',
    };

    static grid = {
        pageSize: 10,
    };

    chatGPT = {
        authorization: 'Bearer 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',
        model: 'text-davinci-003',
        "temperature": 0.5,
        "max_tokens": 800,
        "top_p": 1.0,
        "frequency_penalty": 0.0,
        "presence_penalty": 0.0,
    };

    static currentRoute = {
        state: JSON.parse(localStorage.getItem('currentRouteState') || '{}'),
    };

    static menus = [];

    static isDirty = false;
    static isSmallScreen = (window.innerWidth < 768) ? true : false;
    static isIFrame = (window.self !== window.top) ? true : false;

    static labels = { ..._i18n, ..._labels };
    static ddl = _ddl;
    static columns = _columns;
    static storageUrl = env.urls?.storageUrl || 'https://quizaleprodstg.blob.core.windows.net/';

    static storage = {
        rootPath: Config.storageUrl,
        quizFiles: Config.storageUrl + 'quiz-files/',
        userPhotos: Config.storageUrl + 'users/',
        logoPath: Config.storageUrl + 'logos/',
    };

    static sidebar = {
        display: true,
        width: '250px',
        opened: true
    };

    static getUrl(url: string) {
        return Config.apiUrl + url;
    }

    static urls = {
        auth: Config.getUrl('api/auth/'),
        base: Config.getUrl('api/'),
        cache: Config.getUrl('api/cache/'),
        classes: Config.getUrl('api/classes/'),
        appUsers: Config.getUrl('api/AppUsers/'),
        menus: Config.getUrl('api/Menu/GetMenus/'),
        jobs: Config.getUrl('api/jobs/'),
        masterData: Config.getUrl('api/masterData/'),
        projects: Config.getUrl('api/Projects/'),
        settings: Config.getUrl('api/Settings/'),
        translation: Config.getUrl('api/translations/'),
        games: Config.getUrl('api/Games/'),
        playlists: Config.getUrl('api/Playlists/'),
        quizResults: Config.getUrl('api/QuizResults/'),
        children: Config.getUrl('api/Children/'),
        emailTemplates: Config.getUrl('api/EmailTemplates/'),
        categories: Config.getUrl('api/categories/'),
        sessions: Config.getUrl('api/sessions/'),
        subCategories: Config.getUrl('api/subCategories/'),
        users: Config.getUrl('api/users/'),
        companies: Config.getUrl('api/companies/'),
    };

    public static getApiUrl(key) {
        return this.urls[key] || (Config.apiUrl + key);
    }
    constructor() { }

    static get menuType() {
        return localStorage.getItem('menuType');
    }

    static get homePage() {
        if (localStorage.loginMode === 'quiz') {
            return '#';
        }
        if (!Config.user.isAuthenticated) {
            return '/auth/login';
        }
        if (Config.user.role == 'student') {
            return '/students';
        }
        if (Config.user.role == 'teacher') {
            return '/teachers';
        }
        return '/admin/home';
    }

    static get hostname() {
        // let host = window.location.host;
        // let subdomain = host.split('.')[0];
        let subdomain = localStorage.getItem('hostname') || 'dev';

        if (subdomain.startsWith('localhost')) {
            // subdomain = 'training';
            subdomain = 'dev';
        }
        return subdomain;
    }

    public static langChanged: EventEmitter<string> = new EventEmitter<string>();
    private static _lang = localStorage.getItem('lang') || 'en';

    static set lang(lang: string) {
        localStorage.setItem('lang', lang);
        Config.isRtl = Config.lang === 'he' || Config.lang === 'ar';
        Config.langChanged.emit(lang);
        console.log('lang changed');
    }

    static get lang(): string {
        return this._lang || localStorage.getItem('lang') || 'en';
    }

    static isRtl = Config.lang === 'he' || Config.lang === 'ar';

    static languages = {
        en: 'english',
        he: 'hebrew',
        hi: 'hindi',
        po: 'polish',
        ru: 'russian',
        ar: 'arabic',
    };
    static language = Config.languages[Config.lang];

    public static userChanged: EventEmitter<User> = new EventEmitter<User>();
    public static themeChanged: EventEmitter<string> = new EventEmitter<string>();

    private static _user: User = new User();
    private static _tenant: any = null;
    private static _hostConfig: any = JSON.parse(localStorage.getItem('hostConfig') || '{}');
    private static _quizConfigs: IQuizConfig[] = JSON.parse(localStorage.getItem('quizConfigs') || '{}');

    static set user(user: User) {
        this._user = user;
        this.userChanged.emit(user);
    }

    static get user(): User {
        if (!this._user && localStorage.getItem('profile')) {
            this._user = new User();
        }
        return this._user;
    }

    static get tenant(): any {
        if (!this._tenant && localStorage.getItem('tenant')) {
            this._tenant = JSON.parse(localStorage.getItem('tenant'));
        }
        return this._tenant;
    }

    static set tenant(tenant: any) {
        if (tenant) {
            this._tenant = tenant;
            console.log(tenant);
            localStorage.setItem('tenant', JSON.stringify(tenant));
            localStorage.setItem('tenantId', tenant.id);

        } else {
            localStorage.removeItem('tenant');
        }
    }

    static get hostConfig(): IHostConfig {
        return this._hostConfig;
    }

    static set hostConfig(val: IHostConfig) {
        if (val) {
            this._hostConfig = val;
            localStorage.setItem('hostConfig', JSON.stringify(val));
        } else {
            localStorage.removeItem('hostConfig');
        }
    }

    static get quizConfigs(): IQuizConfig[] {
        return this._quizConfigs;
    }

    static set quizConfigs(val: IQuizConfig[]) {
        if (val) {
            this._quizConfigs = val;
            localStorage.setItem('quizConfigs', JSON.stringify(val));
        } else {
            localStorage.removeItem('quizConfigs');
        }
    }

    static isMobileDevice() {
        let check = false;
        // tslint:disable-next-line: only-arrow-functions
        (function (a) {
            // tslint:disable-next-line:max-line-length
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor);
        return check;
    }
}
