<span class="created-by d-none d-sm-inline">
    <span class="d-none d-sm-inline">Copyright</span>
    &copy; <a href="https://www.quizale.com" target="_blank">Quizale</a> {{currentYear}}
</span>
<div class="socials">
    <a href="#" target="_blank" class="ion ion-social-github"></a>
    <a href="#" target="_blank" class="ion ion-social-facebook"></a>
    <a href="#" target="_blank" class="ion ion-social-twitter"></a>
    <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
</div>
<!-- <div *ngIf="user.isAuthenticated" class="colors text-end">
    <span *ngFor="let item of colors;" class="color px-1" (click)="onColorChange(item);">
        <i class="fa far fa-smile fa-2x {{item.name}}"></i>
    </span>
</div> -->