import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { Config } from '../../../shared/services/config';
import { AuthService } from '../../../shared/services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { MasterService } from '../../../shared/services/master.service';
import { User } from '../../../shared/models/user';
import { MsgService } from '../../../shared/services/msg.service';
import { UserService } from '../../../shared/services/user.service';
import { ConfirmationService } from 'primeng/api';
import { NgIf, NgFor } from '@angular/common';
import { TruncatePipe } from '../../../shared/pipes/truncate.pipe';
import { DialogModule } from 'primeng/dialog';
import { TextDirective } from '../../../shared/directives/text.directive';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgFor,
    TruncatePipe,
    DialogModule,
    TextDirective,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  db = Config;
  labels = Config.labels;
  languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'de', name: 'German' },
    // { code: 'hi', name: 'Hindi' },
    // { code: 'po', name: 'Polish' },
    // { code: 'ar', name: 'Arabic' },
  ];
  user = Config.user;
  fallbackImage = 'images/user.png';
  isIFrame = Config.isIFrame;
  isInventoryUser = false;
  logoPath = Config.storage.logoPath;
  defaultLogoUrl = 'images/default-logo.png';
  logoUrl = '';

  avatars = [
    { img: this.user.photoUrl || this.fallbackImage },
    { img: 'boy.png' },
    { img: 'boy-1.png' },
    { img: 'boy-2.png' },
    { img: 'boy-3.png' },
    { img: 'boy-4.png' },
    { img: 'boy-5.png' },
    { img: 'boy-6.png' },
    { img: 'boy-7.png' },
    { img: 'boy-11.png' },
    { img: 'boy-15.png' },
    { img: 'girl-2.png' },
    { img: 'girl-3.png' },
    { img: 'girl-4.png' },
    { img: 'girl-5.png' },
    { img: 'girl-6.png' },
    { img: 'girl-8.png' },
    // { img: 'girl-10.png' },
    { img: 'girl-11.png' },
  ];

  avatarDialog = {
    visible: false,
  };

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  // user: any;
  homePage = Config.homePage;
  readOnlyMode = window.location.href.includes('/quiz/run/') || (localStorage.loginMode === 'quiz');

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
  ];

  menuTypes = [
    {
      value: 'default',
      name: 'Default',
      defaultUrl: '/dashboard/summary',
    },
    {
      value: 'inventory',
      name: 'Inventory',
      defaultUrl: '/inventory/dashboard',
    },
    {
      value: 'admin',
      name: 'Admin',
      defaultUrl: '/admin/users',
    },
  ];
  menuType = localStorage.getItem('menuType') || 'default';

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private msg: MsgService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private authService: AuthService, private router: Router, private masterService: MasterService,
  ) {
    // this.materialTheme$ = this.themeService.onThemeChange()
    //   .pipe(map(theme => {
    //     const themeName: string = theme?.name || '';
    //     return themeName.startsWith('material');
    //   }));
    // this.themeService.changeTheme('material-light');
    Config.userChanged.subscribe(user => this.onUserChanged(user));

    this.avatars.forEach(x => {
      if (x.img && !x.img.startsWith('http')) {
        x.img = 'images/avatars/' + x.img;
      }
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.logoUrl = this.defaultLogoUrl; // this.logoPath + window.location.hostname + '.png';
    console.log(Config.tenant);
    if (Config.tenant) {
      this.logoUrl = Config.tenant.logoUrl || this.defaultLogoUrl; // this.logoPath + window.location.hostname + '.png';
    }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.userPictureOnly = isLessThanXl;
      });

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
      });

    this.menuService.onItemClick().subscribe(() => {
      console.log('clicked');
      console.log(Config.isSmallScreen);
      if (Config.isSmallScreen) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  onAvatarChange() {
    this.avatarDialog.visible = true;
  }

  setAvatar(avatar) {
    this.user.image = avatar.img;
    Config.user.image = avatar.img;
    const model = {
      image: Config.user.image,
      theme: Config.user.theme,
    };
    Config.user.saveToLocalStorage();
    this.userService.updateSettings(model).subscribe(res => {
      console.log(res);
    });
    this.avatarDialog.visible = false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  get loggedIn() {
    return (this.user && this.user.isAuthenticated);
  }

  onUserChanged(user: User): void {
    this.user = user;
    this.avatars[0].img = this.user.photoUrl || this.fallbackImage;
    this.setMenuType();
    if (this.user && this.user.isAuthenticated) {
      // this.loadProjects();
      this.homePage = Config.homePage;
    }
    this.logoUrl = Config.tenant?.logoUrl || this.defaultLogoUrl;
    this.readOnlyMode = window.location.href.includes('/quiz/run/') || (localStorage.loginMode === 'quiz');
  }

  setMenuType() {
    this.menuType = 'default';
    if (Config.user && (Config.user.isInRole('InventoryUser') || Config.user.isInRole('Admin'))) {
      this.menuType = localStorage.getItem('menuType') || 'default';
      this.masterService.loadMenus();
      this.isInventoryUser = true;
    }
  }

  changeMenuType(event: any) {
    localStorage.setItem('menuType', event);
    this.setMenuType();
    const mt = this.menuTypes.find(x => x.value === this.menuType);
    this.router.navigateByUrl(mt.defaultUrl);
    // window.location.href = window.location.href;
  }

  expireCache() {
    this.masterService.expireCache().subscribe(res => {
      this.msg.success(res.status);
      window.location.reload();
    });
  }

  onLanguageChanged(lang: string) {
    this.confirmationService.confirm({
      message: 'The page will reload. Are you sure you want to change language?',
      header: this.db.labels.areYouSure,
      accept: () => this.lang(lang),
    });
  }

  lang(lang: string) {
    localStorage.setItem('lang', lang);
    this.masterService.getTranslation(lang).subscribe(res => {
      Config.labels = res;
      localStorage.setItem('labels', JSON.stringify(Config.labels));
      Config.lang = lang;
      this.db = Config;
      this.labels = Config.labels;
      window.location.reload();
    });
    // window.location.href = Config.homePage;
  }

  logoff() {
    this.authService.logoff();
    Config.user = new User();
    this.router.navigateByUrl('public/logout');
  }
}
